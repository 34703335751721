<template>
  <v-select v-model="selectedTags" :items="getTags" :label="$t('label.tag')" multiple clearable @change="onChange" :hint="$t('label.tag')" persistent-hint solo :menu-props="{ offsetY: true }">
    <template v-slot:selection="{ index }">
      <span v-if="index == 0">
        {{ selectedTags.length == 1 ? getTagName(selectedTags[0]) : $options.filters.TagListFormatter(selectedTags, getTags.length) }}
      </span>
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon :color="getTagColor(item)" class="mr-2">
              mdi-tag
            </v-icon>
            {{ getTagName(item) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'tag-select',
  components: {
  },
  props: {
    tags: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTags: [],
    }
  },
  computed: {
    getTags(){
      return this.tags.map(tag => tag.id)
    }
  },
  methods: {
    onChange() {
      this.$emit("tagSelected", this.selectedTags)
    },
    getTagName(tagId){
      return this.tags.find(tag => tag.id == tagId).name
    },
    getTagColor(tagId){
      return this.tags.find(tag => tag.id == tagId).color
    }
  },
}
</script>

<style>
</style>