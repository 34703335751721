var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":_vm.getAddresses,"label":_vm.$t('label.address'),"persistent-hint":"","hint":_vm.$t('label.address'),"multiple":"","clearable":"","solo":"","menu-props":{ offsetY: true }},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedAddresses.length == 1 ? _vm.getAddressName(_vm.selectedAddresses[0]) : _vm.$options.filters.AddressListFormatter(_vm.selectedAddresses, _vm.getAddresses.length))+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getAddressName(item))+" "),(_vm.getAddressTag(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"small":"","color":_vm.getAddressTagColor(item)}},on),[_vm._v("mdi-tag")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAddressTagName(item)))])]):_vm._e()],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selectedAddresses),callback:function ($$v) {_vm.selectedAddresses=$$v},expression:"selectedAddresses"}})}
var staticRenderFns = []

export { render, staticRenderFns }