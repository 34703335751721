<template>
    <v-select :value="getSelectedValue" :items="getAddresses" :label="$t('label.address')" persistent-hint :hint="$t('label.address')" solo
        @change="updateRoute" prepend-inner-icon="mdi-wallet">
        <template v-slot:selection="{ item }">
          {{ item != 'ALL' ? getAddressName(item) : $t('label.all') }}
        </template>
        <template v-slot:item="{item, attrs, on}">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-content>
              <v-list-item-title>
                  {{ item != 'ALL' ? getAddressName(item) : $t('label.all') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
  name: 'address-router-select',
  components: {
  },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    path: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      selectedAddress: 'ALL',
    }
  },
  computed: {
    getAddresses(){
      let addressList = []
      addressList.push("ALL")
      addressList.push(...this.addresses.map(address => address.address.address))
      
      return addressList
    },
    getSelectedValue(){
      if (this.$route.params.address){
        return this.$route.params.address
      } else {
        return "ALL"
      }
    }
  },
  methods: {
    getAddressName(address){
      return this.addresses.find(userAddress => userAddress.address.address == address).name
    },
    updateRoute(value) {
      if (value != 'ALL'){
        this.$router.push({ path: `${this.path}/${value}` });
      } else {
        this.$router.push({ path: `${this.path}` });
      }

    }
  },
}
</script>

<style>
</style>