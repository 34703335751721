<template>
  <v-container fluid>
    <v-row v-if="platformContract.platformType == 'UNIDENTIFIED'">
      <span class="mx-1">
        <v-icon>mdi-help-rhombus</v-icon>
      </span>
      <span>{{$t('misc.unidentified-f')}}</span>
    </v-row>
    <v-row v-if="platformContract.platformType == 'BRIDGE'">
      <span class="mx-1">
        <v-icon>mdi-bridge</v-icon>
      </span>
      <span>{{$t('label.bridge')}}</span>
    </v-row>
    <v-row v-else>
      <v-avatar class="mx-1" size="25">
        <v-img :src="require(`../../assets/platform/${platformContract.platformType}.svg`)"></v-img>
      </v-avatar>
      <span>{{ platformContract.platformName }}</span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'platform-component',
  props: {
    platformContract: {
      type: Object,
      required: true
    },
  },
  data() {
    return {}
  },
  computed: {

  }

}
</script>

<style lang="scss" scoped >
</style>