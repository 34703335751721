<template>
  <v-tab-item value="tab-coin" class="pa-3">
    <paged-data-table :namespace="getNamespace" :itemEndpoint="getTableEndpoint" :itemsHeaders="getHeaders" itemKey="id"
      sortBy="value" :sortDesc="true" :query="getSearchQuery" :showExpand="true" :isCard="false">
      <template v-slot:[`item.blockchainType`]="{ item }">
        <v-img :src="require(`../../assets/blockchain/${item.blockchainType}.svg`)" alt="" width="25" aspect-ratio="1"
          contain></v-img>
      </template>
      <template v-slot:[`item.coin`]="{ item }">
          <span class="mx-1">
            <v-avatar size="25" :color="getCoinAvatarColor(item)">
              <template v-if="getCoinImage(item)">
                <v-img :src="getCoinImage(item)"></v-img>
              </template>
              <template v-else>
                <span class="white--text fit-font">{{ item.coin.symbol ? item.coin.symbol[0] : '' }}</span>
              </template>
            </v-avatar>
          </span>
          <span>{{ item.coin.name }}</span>
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        {{ item.totalBalance | CurrencyFormatter(item.coin ? item.coin.symbol : null) }}
      </template>
      <template v-slot:[`item.unitPrice`]="{ item }">
        {{ getCoinPrice(item) | RoundPriceFormatter(loggedUser.settings.currency) }}
      </template>
      <template v-slot:[`item.value`]="{ item }">
          {{ item.totalValue | CurrencyFormatter(loggedUser.settings.currency) }}
        </template>
      <template v-slot:[`item.actions`]="{ item }">
        <external-link-button v-if="item.coin.coingeckoId"
          :redirect="`https://www.coingecko.com/coins/${item.coin.coingeckoId}`"></external-link-button>
        <div v-if="!item.coin.coingeckoId">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="warning">mdi-alert-decagram
              </v-icon>
            </template>
            <span>{{ $t('misc.coinWarning') }}</span>
          </v-tooltip>
        </div>
      </template>
      <!--
      <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xs">
        <v-row class="ma-1">
          <v-col cols="7">
            <span class="mx-1">
          <v-avatar size="25" :color="getCoinAvatarColor(item)">
            <template v-if="getCoinImage(item)">
              <v-img :src="getCoinImage(item)"></v-img>
            </template>
            <template v-else>
              <span class="secondary--text fit-font">{{ item.coin.symbol[0] }}</span>
            </template>
          </v-avatar>
        </span>
        <span>{{ item.coin.name }}</span>
          </v-col>
          <v-col>
            <v-row>
              <b>{{ item.totalValue | CurrencyFormatter(loggedUser.settings.currency) }}</b>
            </v-row>
            <v-row>
              {{ item.totalBalance | CurrencyFormatter(item.coin ? item.coin.symbol : null) }}
            </v-row>
          </v-col>
        </v-row>
      </template>
      -->
      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <td v-if="!$route.params.address" class="text-start">
          <v-btn icon @click="expand(!isExpanded)" class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:expanded-item="{ item: balanceItem }">
        <td :colspan="getHeaders.length" v-if="!$route.params.address">
          <v-data-table :disable-pagination="true" :headers="getExpandedHeaders" :items="balanceItem.balances"
            :hide-default-footer="true" class="expanded-datatable" sort-by="value" :sort-desc="true">
            <template v-slot:[`item.address`]="{ item }">
              {{ item.address | PersonalAddressFormatter }}
            </template>
            <template v-slot:[`item.coin`]="{ balanceItem }">
              <span class="mx-1">
                <v-avatar size="30" :color="getCoinAvatarColor(balanceItem)">
                  <template v-if="getCoinImage(balanceItem)">
                    <v-img :src="getCoinImage(balanceItem)"></v-img>
                  </template>
                  <template v-else>
                    <span class="white--text fit-font">{{ balanceItem.coin.symbol ? balanceItem.coin.symbol[0] : "" }}</span>
                  </template>
                </v-avatar>
              </span>
              <span>{{ balanceItem.coin.name }}</span>
            </template>
            <template v-slot:[`item.balance`]="{ item }">
              {{ item.balance | CurrencyFormatter(balanceItem.coin ? balanceItem.coin.symbol : null) }}
            </template>
            <template v-slot:[`item.unitPrice`]="{ balanceItem }">
              {{ getCoinPrice(balanceItem) | RoundPriceFormatter(loggedUser.settings.currency) }}
            </template>

            <template v-slot:[`item.value`]="{ item }">
              {{ item.balance * getCoinPrice(balanceItem) | CurrencyFormatter(loggedUser.settings.currency) }}
            </template>
          </v-data-table>
        </td>
      </template>
    </paged-data-table>
  </v-tab-item>
</template>

<script>
import ExternalLinkButton from '../button/ExternalLinkButton.vue';
import PagedDataTable from "../table/PagedDataTable.vue";
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
export default {
  name: 'coin-tab',
  components: { PagedDataTable, ExternalLinkButton },
  props: {
    addresses: {
      type: Array,
      required: true,
    },
    blockchainTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableNamespace: "coin-balances",
    }
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapState({
      state(state) {
        return state[this.getNamespace];
      },
    }),
    getNamespace(){
      if (this.$route.params.address){
        return this.tableNamespace + "-" + this.$route.params.address;
      } else {
        return this.tableNamespace;
      }
    },
    getHeaders() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return [
          { text: this.$t('label.blockchain'), value: "blockchainType", sortable: false },
          { text: this.$t('label.asset'), value: "coin" },
          { text: this.$t('label.balance'), value: "balance", sortable: false },
          { text: this.$t('label.unitPrice'), value: "unitPrice", sortable: false },
          { text: this.$t('label.value'), value: "value" },
          { value: "actions", sortable: false }
        ]
      } else {
        return [
          { text: this.$t('label.asset'), value: "coin" },
          { text: this.$t('label.blockchain'), value: "blockchainType", sortable: false },
          { text: this.$t('label.balance'), value: "balance", sortable: false },
          { text: this.$t('label.unitPrice'), value: "unitPrice", sortable: false },
          { text: this.$t('label.value'), value: "value" },
          { text: this.$t('label.link'), value: "actions", sortable: false }
        ]
      }

    },
    getExpandedHeaders() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return [
          { text: this.$t('label.address'), value: "address", sortable: false },
          { text: this.$t('label.balance'), value: "balance" },
          { text: this.$t('label.value'), value: "value" },
          { value: "actions", sortable: false }
        ]
      } else {
        return [{ text: this.$t('label.address'), value: "address", sortable: false },
        { text: this.$t('label.balance'), value: "balance", sortable: false },
        { text: this.$t('label.value'), value: "value", sortable: false },
        { value: "actions", sortable: false }]
      }
    },
    getTableEndpoint() {
      if (!this.$route.params.address) {
        return `/addressBalance/coin/search`
      } else {
        return `/addressBalance/coin/search/${this.$route.params.address}`
      }
    },
    getSearchQuery() {
      let queries = []
      if (this.addresses.length > 0) {
        let addressQuery = "address=in=(" + this.addresses.join(",") + ")"
        queries.push(addressQuery)
      } else {
        queries.push("address=isnull=true")
      }
      if (this.blockchainTypes.length > 0) {
        let blockchainQuery = "blockchainType=in=(" + this.blockchainTypes.join(",") + ")"
        queries.push(blockchainQuery)
      }
      let query = queries.join(" and ")
      return query
    },
  },
  methods: {
    getCoinPrice(item) {
      if (item.coin && item.coin.lastPrice) {
        if (Object.keys(item.coin.lastPrice.prices).length > 0) {
          return item.coin.lastPrice.prices[this.loggedUser.settings.currency]
        }
      }
      return 0
    },
    getCoinImage(item) {
      if (item.coin) {
        if (item.coin.detail && item.coin.detail.imageSmallUrl) {
          return item.coin.detail.imageSmallUrl
        } else {
          try {
            return require("@/assets/fiat/" + item.coin.symbol + ".svg")
          } catch (e) {
            return null;
          }
        }
      }
      return null;
    },
    getCoinAvatarColor(item) {
      let color = null;
      if (!this.getCoinImage(item)) {
        color = "accent"
      }
      return color;
    }
  }

}
</script>

<style lang="scss" scoped >
.fit-font {
  font-size: 0.75em;
}

.main-table>.v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 10px; // add margin between each row
  border: 1px solid #ededed !important; // add border color
  display: block !important; // display table row as block
}
</style>