<template>
    <v-select v-model="selectedAddresses" :items="getAddresses" :label="$t('label.address')" persistent-hint :hint="$t('label.address')" multiple clearable solo  :menu-props="{ offsetY: true }"
        @change="onChange">
        <template v-slot:selection="{ index }">
            <span v-if="index == 0">
                {{ selectedAddresses.length == 1 ? getAddressName(selectedAddresses[0]) : $options.filters.AddressListFormatter(selectedAddresses, getAddresses.length) }}
            </span>
        </template>
        <template v-slot:item="{item, attrs, on}">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-list-item-action>
              <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                  {{ getAddressName(item) }}
                  <v-tooltip top v-if="getAddressTag(item)">
                    <template v-slot:activator="{ on }">
                      <v-icon class="mx-1" v-on="on" small
                        :color="getAddressTagColor(item)">mdi-tag</v-icon>
                    </template>
                    <span>{{ getAddressTagName(item) }}</span>
                  </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
  name: 'address-select',
  components: {
  },
  props: {
    addresses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedAddresses: [],
    }
  },
  computed: {
    getAddresses(){
      return this.addresses.map(address => address.address.address)
    }
  },
  methods: {
    onChange() {
      this.$emit("addressSelected", this.selectedAddresses)
    },
    getAddressName(address){
      return this.addresses.find(userAddress => userAddress.address.address == address).name
    },
    getAddressTag(address){
      return this.addresses.find(userAddress => userAddress.address.address == address).tag
    },
    getAddressTagColor(address){
      return this.getAddressTag(address).color
    },
    getAddressTagName(address){
      return this.getAddressTag(address).name
    }
  },
}
</script>

<style>
</style>