<template>
    <v-container fluid>
        <v-row v-for="(movement, i) in getMovements" :key="i" :class="i != 0 ? 'mt-5' : ''">
            <coin-value-component v-if="movement.assetType == 'COIN'" :image="getCoinImage(movement)"
                :quantity="movement.quantity" :symbol="movement.coin.symbol" :value="getCoinValue(movement)"
                :hideValues="true">
            </coin-value-component>
            <nft-component v-if="movement.assetType == 'NFT'" :image="getNftImage(movement)"
                :name="getNftName(movement)" :symbol="movement.tokenSymbol" :quantity="movement.quantity">
            </nft-component>
        </v-row>
        <v-row v-if="movements.length > 3 && !showAll">
            <v-col>
                <span>{{ $t('misc.showAll') }}</span>
                <v-icon>mdi-chevron-down</v-icon>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CoinValueComponent from './CoinValueComponent.vue';
import NftComponent from './NftComponent.vue';
import { mapFields } from 'vuex-map-fields';

export default {
    components: { CoinValueComponent, NftComponent },
    name: 'received-sent-component',
    props: {
        movements: {
            type: Array,
            required: true
        },
        showAll: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            maxItems: 3
        };
    },
    computed: {
        ...mapFields("loggedUser", ["loggedUser"]),
        getMovements() {
            if (this.showAll) {
                return this.movements
            } else {
                return this.movements.slice(0, this.maxItems)
            }
        },
    },
    methods: {

        getCoinValue(coinMovement) {
            let coinValue;
            if (coinMovement.price && coinMovement.price.prices && coinMovement.price.prices[this.loggedUser.settings.currency]) {
                coinValue = coinMovement.quantity * coinMovement.price.prices[this.loggedUser.settings.currency]
            } else if (coinMovement.coin && coinMovement.coin.lastPrice && coinMovement.coin.lastPrice.prices && coinMovement.coin.lastPrice.prices[this.loggedUser.settings.currency]) {
                coinValue = coinMovement.quantity * coinMovement.coin.lastPrice.prices[this.loggedUser.settings.currency]
            } else {
                coinValue = 0
            }
            return coinValue
        },
        getCoinImage(item) {
            if (item.coin) {
                if (item.coin.detail && item.coin.detail.imageSmallUrl) {
                    return item.coin.detail.imageSmallUrl
                } else {
                    try {
                        return require("@/assets/fiat/" + item.coin.symbol + ".svg")
                    } catch (e) {
                        return null;
                    }
                }
            }
            return null;
        },
        getNftImage(nftMovement) {
            let tokenImage = null
            if (nftMovement.nft && nftMovement.nft.tokenImage) {
                tokenImage = nftMovement.nft.tokenImage
            }
            return tokenImage
        },
        getNftName(nftMovement) {
            let tokenName;
            if (nftMovement.nft && nftMovement.nft.tokenName) {
                tokenName = nftMovement.nft.tokenName
            } else {
                tokenName = nftMovement.tokenName + " - " + nftMovement.tokenId
            }
            return tokenName;
        }
    }
}
</script>

<style>

</style>