<template>
  <v-row>
    <v-col>
      <v-card>
        <v-tabs v-model="tab" dark background-color="accent" class="tabs-rounded" fixed-tabs>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-coin">
          {{ $t('label.coin') }}
        </v-tab>
        <!--
        <v-tab href="#tab-defi" disabled>
          {{ $t('label.defi') }}
        </v-tab>
        -->
        <v-tab href="#tab-nft">
          {{ $t('label.nft') }}
        </v-tab>
        <v-tab href="#tab-history">
          {{ $t('label.history') }}
        </v-tab>
        <v-spacer />
      </v-tabs>
      
      <v-tabs-items v-model="tab">
        <coin-tab :addresses="addresses" :blockchainTypes="blockchainTypes"></coin-tab>
        <!--<defi-tab :addresses="addresses" :blockchainTypes="blockchainTypes"></defi-tab>-->
        <nft-tab :addresses="addresses" :blockchainTypes="blockchainTypes"></nft-tab>
        <history-tab :addresses="addresses" :blockchainTypes="blockchainTypes"></history-tab>
      </v-tabs-items>
      </v-card>
      
    </v-col>
  </v-row>
</template>

<script>
import CoinTab from './CoinTab.vue'
//import DefiTab from './DefiTab.vue';
import HistoryTab from './HistoryTab.vue';
import NftTab from './NftTab.vue';
export default {
  name: "overview-tabs",
  props: {
    addresses: {
      type: Array,
      required: true
    },
    blockchainTypes: {
      type: Array,
      required: true
    }
  },
  components: { CoinTab, 
    // DefiTab, 
    NftTab, HistoryTab },
  data() {
    return {
      tab: 'tab-coin',
    };
  },
  methods: {

  }

}
</script>

<style lang="scss" scoped>
.tabs-rounded {
  border-top-left-radius: $border-radius-root;
  border-top-right-radius: $border-radius-root;
}
</style>