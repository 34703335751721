<template>
  <v-btn icon :href="redirect" target="_blank">
      <v-icon :small="small">
        mdi-open-in-new
      </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'external-link-button',
  props: {
    redirect: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
}
</script>

<style>
</style>