<template>
  <v-card height="100%" :width="$vuetify.breakpoint.xs ? '100vw' : ''" class="pa-1">
    <v-card-title>
      <v-row>
        <v-col>
          <template v-if="balanceType == 'ASSET'">
            {{ $t('connection.assetBreakdown') }}
          </template>
          <template v-if="balanceType == 'BLOCKCHAIN'">
            {{ $t('connection.platformBreakdown') }}
          </template>
          <template v-if="balanceType == 'ADDRESS'">
            {{ $t('connection.addressBreakdown') }}
          </template>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-for="(balance, i) in getBalances" :key="i" :align="'center'" >
        <v-col cols="3">
          <template v-if="balanceType == 'ASSET'">
            <b>{{ balance.assetType | AssetTypeFormatter }}</b>
          </template>
          <template v-if="balanceType == 'BLOCKCHAIN'">
            <v-avatar size="25" class="mx-1">
            <v-img :src="require(`../../assets/blockchain/${balance.blockchainType}.svg`)" contain>
            </v-img>
        </v-avatar>
          </template>
          <template v-if="balanceType == 'ADDRESS'">
            <v-row :align="'center'">
            <b class="ml-3">{{ getUserAddressFromAddress(balance.address) ?
              getUserAddressFromAddress(balance.address).name : null
            }}</b>
            <v-tooltip v-if="getUserAddressFromAddress(balance.address).tag" top>
              <template v-slot:activator="{ on }">
                <v-icon class="mx-1" v-on="on" small
                  :color="getAddressTagColor(getUserAddressFromAddress(balance.address))">mdi-tag</v-icon>
              </template>
              <span>{{ getAddressTagName(getUserAddressFromAddress(balance.address)) }}</span>
            </v-tooltip>
          </v-row>
          </template>
        </v-col>

        <v-col>
          <v-progress-linear height="20" :value="balance.value / getBalancesTotalValue * 100" rounded
            :color="$BLUE_PALETTE[i % $BLUE_PALETTE.length]" background-color="background">
            <template v-slot:default="{ value }">
              <span :class="value > 50 ? 'white--text' : 'primary--text'">{{ Math.ceil(value) }}%</span>
            </template>
          </v-progress-linear>
        </v-col>
        <v-col>
          <h3>{{ balance.value | CurrencyFormatter(loggedUser.settings.currency) }}</h3>
        </v-col>
      </v-row>
      <v-row :align="'center'" :justify="'end'"
        v-if="(balanceType == 'ADDRESS' || balanceType == 'BLOCKCHAIN') && (balances.length > maxLimit) && limit">
        <v-spacer></v-spacer>
        <template v-if="showMore">
          <span class="mx-1">{{ $t('label.showLess') }}</span>
          <v-btn icon @click="showMore = !showMore" class="mb-1 mr-3">
            <v-icon>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </template>
        <template v-else>
          <span class="mx-1">{{ $t('label.showMore') }}</span>
          <v-btn icon @click="showMore = !showMore" class="mb-1 mr-3">
            <v-icon>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
export default {
  name: "overview-card",
  props: {
    balances: {
      type: Array,
      required: true
    },
    balanceType: {
      type: String,
      required: true
    },
    limit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showMore: false,
      maxLimit: 3
    };
  },
  computed: {
    ...mapFields("userAddresses", ["addresses"]),
    ...mapFields("loggedUser", ["loggedUser"]),
    getBalancesTotalValue() {
      return this.balances.map((balance) => balance.value).reduce((a, b) => a + b);
    },
    getBalances() {
      let sortedBalances = [];
      if (this.balanceType == 'ASSET') {
        sortedBalances = [...this.balances].sort((a, b) => a.assetType - b.assetType)
      } else {
        sortedBalances = [...this.balances].sort((a, b) => b.value - a.value)
      }
      if (this.showMore || !this.limit) {
        return sortedBalances;
      } else {
        return sortedBalances.slice(0, this.maxLimit)
      }


    }
  },
  methods: {
    getUserAddressFromAddress(address) {
      return this.addresses.filter(userAddress => userAddress.address.address === address)[0]
    },
    getAddressTagColor(address) {
      if (address.tag) {
        return address.tag.color
      }
      return null
    },
    getAddressTagName(address) {
      if (address.tag) {
        return address.tag.name
      }
      return null
    },
  }
}
</script>

<style></style>