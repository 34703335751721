<template>
    <div>
      <span class="mx-1">
        <v-icon>mdi-gas-station</v-icon>
      </span>
      <span>{{ quantity | BlockchainCoinFormatter(blockchainType) }} ({{ value | UnhideCurrencyFormatter(this.loggedUser.settings.currency) }})</span>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
export default {
  name: 'gas-value-component',
  props: {
    blockchainType: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: false
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"])
  }

}
</script>

<style scoped lang="scss">

</style>