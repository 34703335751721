var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":_vm.getTags,"label":_vm.$t('label.tag'),"multiple":"","clearable":"","hint":_vm.$t('label.tag'),"persistent-hint":"","solo":"","menu-props":{ offsetY: true }},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedTags.length == 1 ? _vm.getTagName(_vm.selectedTags[0]) : _vm.$options.filters.TagListFormatter(_vm.selectedTags, _vm.getTags.length))+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getTagColor(item)}},[_vm._v(" mdi-tag ")]),_vm._v(" "+_vm._s(_vm.getTagName(item))+" ")],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})}
var staticRenderFns = []

export { render, staticRenderFns }