<template>
  <v-container fluid>
    <v-row :align="'center'">
      <span class="mx-1">
        <v-badge :value="quantity > 1" color="#121212" :content="$options.filters.HideValueFormatter(getQuantity)" overlap left class="primary--text">
          <v-avatar size="25" :color="getAvatarColor">
            <template v-if="image && image != ''">
              <v-img :src="image" :lazy-src="require(`../../assets/images/NO_IMAGE_${loggedUser.settings.theme}.png`)">
                <template v-slot:placeholder>
                    <v-img :src="require(`../../assets/images/NO_IMAGE_${loggedUser.settings.theme}.png`)"></v-img>
                  </template>
              </v-img>
            </template>
            <template v-else>
              <span class="secondary--text fit-font">{{ symbol[0] }}</span>
            </template>
          </v-avatar>
        </v-badge>
      </span>
      <span v-html="$options.filters.TruncateStringFormatter(name, getMaxCharacters)"></span>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {
  name: 'nft-component',
  props: {
    image: {
      type: String,
      required: false
    },
    symbol: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    quantity: {
      type: Number,
      required: false
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    getAvatarColor() {
      let color = null;
      if (!this.image) {
        color = "primary"
      }
      return color;
    },
    getQuantity() {
      let quantity = null;
      if (this.quantity < 100) {
        quantity = this.quantity;
      } else {
        quantity = "99+";
      }
      return quantity;
    },
    getMaxCharacters(){
      if (this.$vuetify.breakpoint.xs){
        return 20;
      } else if (this.$vuetify.breakpoint.sm){
        return 20;
      } else {
        return 32;
      }
    }
  }
}
</script>

<style lang="scss" scoped >
.fit-font {
  font-size: 0.75em;
}
</style>