<template>
    <v-autocomplete v-model="selectedBlockchains" :items="$SUPPORTED_BLOCKCHAINS.concat($SUPPORTED_EXCHANGES)" :label="$t('label.blockchain')" multiple clearable persistent-hint :hint="$t('label.blockchain')" solo
        @change="onChange">
        <template v-slot:selection="{ index }">
            <span v-if="index == 0">
                {{ selectedBlockchains | BlockchainTypeListFormatter($SUPPORTED_BLOCKCHAINS.concat($SUPPORTED_EXCHANGES)) | BlockchainTypeFormatter }}
            </span>
        </template>
        <template v-slot:item="{item, attrs, on}">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-list-item-action>
              <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <blockchain-type-component :blockchainType="item"></blockchain-type-component>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
import BlockchainTypeComponent from '../misc/BlockchainTypeComponent.vue'
export default {
  name: 'blockchain-select',
  components: {
    BlockchainTypeComponent
  },
  data() {
    return {
      selectedBlockchains: [],
    }
  },
  methods: {
    onChange() {
      this.$emit("blockchainTypeSelected", this.selectedBlockchains)
    }
  },
  created() {
  }
}
</script>

<style>
</style>