<template>
  <div>
    <span class="mx-1">
      <v-avatar size="25" :color="getAvatarColor">
        <template v-if="image">
          <v-img :src="image"></v-img>
        </template>
        <template v-else>
          <span class="white--text fit-font">{{ symbol[0] }}</span>
        </template>
      </v-avatar>
    </span>
    <span v-if="hideValues">{{ quantity | CurrencyFormatter(symbol) }} ({{ value | CurrencyFormatter(loggedUser.settings.currency)
    }})</span>
    <span v-else>{{ quantity | UnhideCurrencyFormatter(symbol) }} ({{ value | UnhideCurrencyFormatter(loggedUser.settings.currency)
    }})</span>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {
  name: 'coin-value-component',
  props: {
    image: {
      type: String,
      required: false
    },
    symbol: {
      type: String,
      required: false
    },
    quantity: {
      type: Number,
      required: false
    },
    value: {
      type: Number,
      required: false
    },
    hideValues: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    getAvatarColor() {
      let color = null;
      if (!this.image) {
        color = "accent"
      }
      return color;
    }
  }

}
</script>

<style scoped lang="scss">
.fit-font {
  font-size: 0.75em;
}
</style>