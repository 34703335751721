var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.$SUPPORTED_BLOCKCHAINS.concat(_vm.$SUPPORTED_EXCHANGES),"label":_vm.$t('label.blockchain'),"multiple":"","clearable":"","persistent-hint":"","hint":_vm.$t('label.blockchain'),"solo":""},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("BlockchainTypeFormatter")(_vm._f("BlockchainTypeListFormatter")(_vm.selectedBlockchains,_vm.$SUPPORTED_BLOCKCHAINS.concat(_vm.$SUPPORTED_EXCHANGES))))+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('blockchain-type-component',{attrs:{"blockchainType":item}})],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selectedBlockchains),callback:function ($$v) {_vm.selectedBlockchains=$$v},expression:"selectedBlockchains"}})}
var staticRenderFns = []

export { render, staticRenderFns }