<template>
    <v-select v-model="select" :items="getItems" :label="$t('label.transactionType')" multiple clearable :hint="$t('label.transactionType')"
        @change="onChange" persistent-hint solo>
        <template v-slot:selection="{ index }">
            <span v-if="index == 0">
                {{ select | TransactionTypeListFormatter(getItems.length) | TransactionActionTypeStringFormatter }}
            </span>
        </template>
        <template v-slot:item="{item, attrs, on}">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-list-item-action>
              <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>{{ item | TransactionActionTypeIconFormatter }}</v-icon>
                <span class="mx-1">{{ item | TransactionActionTypeStringFormatter }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
    </v-select>
</template>

<script>
import { getTransactionActionTypesFromGroupValue } from "../../utils/utils";
export default {
    name: "transaction-action-type-filter",
    components: {},
    data() {
        return {
            select: [],
            items: ["ERROR", "CONTRACT_INTERACTION", "SELF", "SEND", "RECEIVE", "DEPOSIT", "WITHDRAW", "STAKE", "UNSTAKE", "BURN", "WRAP", "UNWRAP", "AIRDROP", "REWARD", "MINT", "BUY", "SELL", "CLAIM", "SWAP", "ADD_LIQUIDITY", "REMOVE_LIQUIDITY", "BRIDGE_WITHDRAW", "BRIDGE_DEPOSIT", "PAYMENT", "PAYMENT_REFUND", "CASHBACK", "CASHBACK_REVERTED", "REIMBURSEMENT", "REIMBURSEMENT_REVERTED", "INTEREST", "REPAY_CREDIT", "FEE", "MARGIN_GAIN", "MARGIN_LOSS", "FUTURE_PROFIT", "FUTURE_LOSS", "REALIZED_PROFIT", "REALIZED_LOSS", "UNIDENTIFIED", "LOST", "DONATION", "BORROW", "LEND", "UNLEND", "MINING", "COLLATERAL_ADDED", "COLLATERAL_RETURNED", "NOT_TAXABLE_EVENT"],
        }
    },
    computed: {
        getItems() {
            return [...this.items].sort()
        }
    },
    methods: {
        onChange() {
            let newSelect = this.select.map(item => getTransactionActionTypesFromGroupValue(item)).flat()
            this.$emit("filterTransactionType", newSelect)
        }
    }
}
</script>

<style>
</style>