<template>
  <v-dialog v-model="dialog" @click:outside="close" persistent max-width="800px">
    <v-card class="dialog-top-color">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>
          {{ $t('transaction.transactionDetailTitle') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <external-link-button v-if="!isExchange(address)"
          :redirect="$options.filters.TransactionScanUrlFormatter(transaction.hash, transaction.blockchainType)">
        </external-link-button>
        <v-btn icon @click="close">
          <v-icon color="error">
            mdi-close-box
          </v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-row :align="'center'">
            <v-col>
              <b>{{ $t("label.date") }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'">
                {{ transaction.created | DateFormatter }}
              </v-row>
            </v-col>
          </v-row>
          <v-row :align="'center'">
            <v-col>
              <b>{{ $t('label.blockchain') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'">
                <blockchain-type-component :blockchainType="transaction.blockchainType"></blockchain-type-component>
              </v-row>
            </v-col>
          </v-row>
          <v-row :align="'start'" v-if="!isExchange(address)">
            <v-col>
              <b>{{ $t('label.from') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'" v-if="transaction && transaction.transaction && transaction.transaction.sender">
                {{ transaction ? transaction.transaction.sender : "" | AddressFormatter }}<copy-address-button
                  :address="transaction.transaction.sender">
                </copy-address-button>
              </v-row>
              <v-row :align="'center'" v-else v-for="(sender, i) in transaction.transaction.senders" :key="i">
                {{ sender | AddressFormatter }}<copy-address-button
                  :address="sender">
                </copy-address-button>
              </v-row>
            </v-col>
          </v-row>
          <v-row :align="'start'" v-if="!isExchange(address)">
            <v-col>
              <b>{{ $t('label.to') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'" v-if="transaction && transaction.transaction && transaction.transaction.recipient">
                {{ transaction ? transaction.transaction.recipient : "" | AddressFormatter }}<copy-address-button
                  :address="transaction.transaction.recipient">
                </copy-address-button>
              </v-row>
              <v-row :align="'center'" v-else v-for="(recipient, i) in transaction.transaction.recipients" :key="i">
                {{ recipient | AddressFormatter }}<copy-address-button
                  :address="recipient">
                </copy-address-button>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="transaction.counterparty" :align="'center'">
            <v-col>
              <b>{{ $t('label.counterparty') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'">
                {{ transaction.counterparty | PersonalAddressFormatter }} <copy-address-button
                  :address="transaction.counterparty">
                </copy-address-button>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="transaction.platformContract && transaction.platformContract.platformType != 'UNIDENTIFIED' && !isExchange(address)"
            :align="'center'">
            <v-col>
              <b>{{ $t('label.platformType') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'">
                <platform-component :platformContract="transaction.platformContract"></platform-component>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="transaction.sent.length > 0" :align="'center'">
            <v-col>
              <b>{{ $t('label.sent') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'">
                <received-sent-component :movements="transaction.sent" :showAll="true"></received-sent-component>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="transaction.received.length > 0" :align="'center'">
            <v-col>
              <b>{{ $t('label.received') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'">
                <received-sent-component :movements="transaction.received" :showAll="true"></received-sent-component>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="transaction.fee" :align="'center'">
            <v-col>
              <b>{{ $t('label.gasFee') }}</b>
            </v-col>
            <v-col>
              <v-row :align="'center'">
                <gas-value-component :quantity="transaction.fee" :blockchainType="transaction.feeMovement.coin.symbol"
                  :value="getFeeCurrencyValue"></gas-value-component>
              </v-row>

            </v-col>
          </v-row>
        </template>
        <template v-if="$vuetify.breakpoint.xs">
          <v-row :align="'center'">
            <v-col>
              <b class="mx-1">{{ $t("label.date") }}</b>
            </v-col>
          </v-row>
          <v-row :align="'center'" no-gutters>
            <v-col class="mx-1">
              {{ transaction.created | DateFormatter }}
            </v-col>
          </v-row>
          <v-row :align="'center'">
            <v-col>
              <b class="mx-1">{{ $t('label.blockchain') }}</b>
            </v-col>
          </v-row>
          <v-row :align="'center'" no-gutters class="mt-1">
            <v-col>
              <blockchain-type-component :blockchainType="transaction.blockchainType"></blockchain-type-component>
            </v-col>
          </v-row>
          <v-row :align="'center'" v-if="!isExchange(address)">
            <v-col>
              <b class="mx-1">{{ $t('label.from') }}</b>
            </v-col>
          </v-row>
          <v-row :align="'center'" v-if="!isExchange(address)" no-gutters>
            <div class="mx-1">{{ transaction ? transaction.transaction.sender : "" | AddressFormatter }}</div>
            <copy-address-button :address="transaction.transaction.sender">
            </copy-address-button>
          </v-row>
          <v-row :align="'center'" v-if="!isExchange(address)">
            <v-col>
              <b class="mx-1">{{ $t('label.to') }}</b>
            </v-col>
          </v-row>
          <v-row :align="'center'" no-gutters v-if="!isExchange(address)">
            <div class="mx-1">{{ transaction.transaction.recipient | AddressFormatter }}</div>
            <copy-address-button :address="transaction.transaction.recipient">
            </copy-address-button>
          </v-row>
          <v-row v-if="transaction.counterparty" :align="'center'">
            <v-col>
              <b class="mx-1">{{ $t('label.counterparty') }}</b>
            </v-col>
          </v-row>
          <v-row v-if="transaction.counterparty" :align="'center'" no-gutters>
            <div class="mx-1">{{ transaction.counterparty | AddressFormatter }}</div>
            <copy-address-button :address="transaction.counterparty">
            </copy-address-button>
          </v-row>
          <v-row
            v-if="transaction.platformContract && transaction.platformContract.platformType != 'UNIDENTIFIED' && !isExchange(address)"
            :align="'center'">
            <v-col>
              <b class="mx-1">{{ $t('label.platformType') }}</b>
            </v-col>
          </v-row>
          <v-row
            v-if="transaction.platformContract && transaction.platformContract.platformType != 'UNIDENTIFIED' && !isExchange(address)"
            :align="'center'" no-gutters class="mt-1">
            <v-col>
              <platform-component :platformContract="transaction.platformContract"></platform-component>
            </v-col>
          </v-row>
          <v-row v-if="transaction.sent.length > 0" :align="'center'">
            <v-col>
              <b class="mx-1">{{ $t('label.sent') }}</b>
            </v-col>
          </v-row>
          <v-row v-if="transaction.sent.length > 0" :align="'center'" no-gutters class="mt-1">
            <v-col>
              <received-sent-component :movements="transaction.sent" :showAll="true"></received-sent-component>
            </v-col>
          </v-row>
          <v-row v-if="transaction.received.length > 0" :align="'center'">
            <v-col>
              <b class="mx-1">{{ $t('label.received') }}</b>
            </v-col>
          </v-row>
          <v-row v-if="transaction.received.length > 0" :align="'center'" no-gutters class="mt-1">
            <v-col>
              <received-sent-component :movements="transaction.received" :showAll="true"></received-sent-component>
            </v-col>
          </v-row>
          <v-row v-if="transaction.fee" :align="'center'">
            <v-col>
              <b class="mx-1">{{ $t('label.gasFee') }}</b>
            </v-col>
          </v-row>
          <v-row v-if="transaction.fee" :align="'center'" no-gutters class="mt-1">
            <v-col>
              <gas-value-component :quantity="transaction.fee" :blockchainType="transaction.blockchainType"
                :value="getFeeCurrencyValue"></gas-value-component>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ExternalLinkButton from '../button/ExternalLinkButton.vue'
import GasValueComponent from '../misc/GasValueComponent.vue'
import PlatformComponent from '../misc/PlatformComponent.vue'
import ReceivedSentComponent from '../misc/ReceivedSentComponent.vue'
import BlockchainTypeComponent from '../misc/BlockchainTypeComponent.vue'
import CopyAddressButton from '../button/CopyAddressButton.vue'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'transaction-detail-dialog',
  components: { ExternalLinkButton, GasValueComponent, ReceivedSentComponent, PlatformComponent, BlockchainTypeComponent, CopyAddressButton },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    address: {
      type: String,
      required: true
    },
    transaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    getFeeCurrencyValue() {
      let feeValue = 0
      if (this.transaction.feePrice && this.transaction.feePrice.prices && this.transaction.feePrice.prices[this.loggedUser.settings.currency]) {
        feeValue = this.transaction.feePrice.prices[this.loggedUser.settings.currency]
      }
      return this.transaction.fee * feeValue
    },
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    isExchange(address) {
      return this.$SUPPORTED_EXCHANGES.some((exchange) => {
        if (address.toLowerCase().startsWith(exchange.toLowerCase())) {
          return true;
        }
      })
    }
  }

}
</script>

<style></style>