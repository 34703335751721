<template>
  <v-tab-item value="tab-nft" class="pa-3">
    <paged-data-table :namespace="getNamespace" :itemEndpoint="getTableEndpoint" :itemsHeaders="getHeaders"
      itemKey="id" :query="getSearchQuery" :showExpand="true" sortBy="value" :sortDesc="true" :isCard="false">
      <template v-slot:[`item.blockchainType`]="{ item }">
        <v-img :src="require(`../../assets/blockchain/${item.blockchainType}.svg`)" alt="" width="25" aspect-ratio="1"
          contain></v-img>
      </template>
      <template v-slot:[`item.totalBalance`]="{ item }">
        {{ item.totalBalance | HideValueFormatter }}
      </template>
      <template v-slot:[`item.collection`]="{ item }">
        <nft-component :image="item.collection.collectionProfileImage" :name="getCollectionName(item)"
          :symbol="getCollectionName(item)"></nft-component>
      </template>
      <template v-slot:[`item.floor`]="{ item }">
        <template  v-if="getSortedItemCollectionStatsByMarketplace(item).length > 0">
           <v-row v-for="(stat, i) in getSortedItemCollectionStatsByMarketplace(item)" :key="i" class="my-3"
          :align="'center'">
          <!--
          <v-avatar size="15" class="mx-1">
            <v-img :src="require(`../../assets/platform/${stat.marketplaceType}.svg`)"></v-img>
          </v-avatar>
          -->
          <template v-if="i == 0">
            <coin-value-component :image="getCollectionBlockchainCoinImage(item)" :quantity="stat.floorPrice"
            :symbol="item.collection.blockchainCoin.symbol"
            :value="stat.floorPrice * item.collection.blockchainCoin.lastPrice.prices[loggedUser.settings.currency]">
          </coin-value-component>
          </template>

                  </v-row>
        </template>
        <template v-else>
           -
        </template>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        {{ item.totalValue | CurrencyFormatter(loggedUser.settings.currency) }}
      </template>
      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded, item }">
        <td class="text-start">
          <v-btn icon @click="expand(!isExpanded); expandItem(item.collection)" class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:expanded-item="{ item: balanceItem }">
        <td :colspan="getHeaders.length">
          <v-data-table :disable-pagination="true" :headers="getExpandedHeaders" :items="sortedBalances(balanceItem, balanceItem.collection)"
            :hide-default-footer="true" class="expanded-datatable">
            <template v-slot:[`item.address`]="{ item }">
              {{ item.address | PersonalAddressFormatter }}
            </template>
            <template v-slot:[`item.asset`]="{ item }">
              <nft-component :image="item.nft.tokenImage"
                :name="item.nft.tokenName ? item.nft.tokenName : item.nft.tokenId"
                :symbol="item.nft.tokenName ? item.nft.tokenName : item.nft.tokenId" :quantity="item.balance">
              </nft-component>
            </template>
            <!--
            <template v-slot:[`item.rarity`]="{ item }">
              <v-row :align="'center'" v-if="getNftRarity(item)">
                <v-icon left small>
                    mdi-diamond-stone
                  </v-icon>
                  <span>
                    {{getNftRarity(item)}}
                  </span>
              </v-row>
              <v-row v-else>
                -
              </v-row>
            </template>
            <template v-slot:[`item.tier`]="{ item }">
              <template v-if="item.nft && item.nft.collection && item.nft.collection.rarity && item.nft.collection.rarity.totalSupply > 0">
                {{getNftRarity(item) / item.nft.collection.rarity.totalSupply * 100 | RarityTierFormatter}}
              </template>
              <template v-else>
                -
              </template>
            </template>
            -->
            <template v-slot:[`item.value`]="{ item }">
              {{ getAssetEstimatedValue(item, balanceItem.collection) | CurrencyFormatter(loggedUser.settings.currency) }}
            </template>
            
          </v-data-table>
        </td>
      </template>

    </paged-data-table>

    <!--<nft-detail-dialog :dialog.sync="dialog" :nftBalance="selectedNft" :collection="selectedCollection"></nft-detail-dialog>-->
  </v-tab-item>
</template>

<script>
//import NftDetailDialog from '../dialog/NftDetailDialog.vue';
import CoinValueComponent from '../misc/CoinValueComponent.vue';
import PagedDataTable from "../table/PagedDataTable.vue";
import NftComponent from '../misc/NftComponent.vue';
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
export default {
  name: 'nft-tab',
  components: { PagedDataTable, CoinValueComponent, 
    //NftDetailDialog, 
    NftComponent },
  props: {
    addresses: {
      type: Array,
      required: true,
    },
    blockchainTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      selectedNft: {},
      selectedCollection: {},
      tableNamespace: "nft-balances",
    }
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    getHeaders() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return [
          { text: this.$t('label.blockchain'), value: "blockchainType", sortable: false },
          { text: this.$t('label.collection'), value: "collection", sortable: false },
          { text: this.$t('label.balance'), value: "totalBalance" },
          { text: this.$t('label.floor'), value: "floor", sortable: false },
          { text: this.$t('label.estimatedValue'), value: "value" },
        ]
      } else {
        return [
          { text: this.$t('label.collection'), value: "collection", sortable: false },
          { text: this.$t('label.blockchain'), value: "blockchainType", sortable: false },
          { text: this.$t('label.balance'), value: "totalBalance" },
          { text: this.$t('label.floor'), value: "floor", sortable: false },
          { text: this.$t('label.estimatedValue'), value: "value" },
        ]
      }
    },
    getExpandedHeaders() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return [
          { text: this.$t('label.address'), value: "address", sortable: false },
          { text: this.$t('label.asset'), value: "asset", sortable: false },
          //{ text: this.$t('label.rarity'), value: "rarity", sortable: false },
          //{ text: this.$t('label.tier'), value: "tier", sortable: false },
          { text: this.$t('label.estimatedValue'), value: "value", sortable: false },
        ]
      } else {
        return [
          { text: this.$t('label.address'), value: "address", sortable: false },
          { text: this.$t('label.asset'), value: "asset", sortable: false },
          //{ text: this.$t('label.rarity'), value: "rarity", sortable: false },
          //{ text: this.$t('label.tier'), value: "tier", sortable: false },
          { text: this.$t('label.estimatedValue'), value: "value", sortable: false },
        ]
      }

    },
    getTableEndpoint() {
      if (!this.$route.params.address) {
        return `/addressBalance/nft/search`
      } else {
        return `/addressBalance/nft/search/${this.$route.params.address}`
      }
    },
    getNamespace(){
      if (this.$route.params.address){
        return this.tableNamespace + "-" + this.$route.params.address;
      } else {
        return this.tableNamespace;
      }
    },
    ...mapState({
      state(state) {
        return state[this.getNamespace];
      },
    }),
    getSearchQuery() {
      let queries = []
      if (this.addresses.length > 0) {
        let addressQuery = "address=in=(" + this.addresses.join(",") + ")"
        queries.push(addressQuery)
      } else {
        queries.push("address=isnull=true")
      }
      if (this.blockchainTypes.length > 0) {
        let blockchainQuery = "blockchainType=in=(" + this.blockchainTypes.join(",") + ")"
        queries.push(blockchainQuery)
      }
      let query = queries.join(" and ")
      return query
    },
  },
  methods: {
    expandItem(item){
      this.selectedCollection = item
    },
    getNftRarity(item){
      if (item.nft.tokenId && item.nft.collection && item.nft.collection.rarity){
        let rarities = item.nft.collection.rarity.rarities
        if (Object.keys(rarities).length > 0){
          let tokenId = item.nft.tokenId
          return rarities[tokenId]
        } else {
          return null
        }
      }
      return null;
    },
    sortedBalances(item, collection){
      return [...item.balances].sort((a, b) => this.getAssetEstimatedValue(b, collection) - this.getAssetEstimatedValue(a, collection));
    },
    getCollectionName(item){
      if (item.collection.collectionName && item.collection.collectionName.length > 0) {
        return item.collection.collectionName
      } else {
        if (item.balances[0].nft != null && item.balances[0].nft.tokenName != null && item.balances[0].nft.tokenName.length > 0) {
          return item.balances[0].nft.tokenName
        } else {
          return item.balances[0].nft.contractAddress
        }
      }
    },
    getCollectionBlockchainCoinImage(item) {
      if (item.collection && item.collection.blockchainCoin && item.collection.blockchainCoin.detail) {
        return item.collection.blockchainCoin.detail.imageSmallUrl
      } else {
        return null;
      }
    },
    getSortedItemCollectionStatsByMarketplace(item) {
      if (item.collection && item.collection.stats) {
        return [...item.collection.stats].sort((a, b) => {
          return b.marketplaceType > a.marketplaceType ? 1 : -1;
        })
      } else {
        return null;
      }
    },
    getAssetEstimatedValue(item, collection) {
      let value = 0;
      switch (this.loggedUser.settings.nftEvaluationMethod) {
        case "FLOOR":
          if (collection.stats.filter(x => x.marketplaceType == 'OPENSEA').length > 0){
            value = collection.stats.filter(x => x.marketplaceType == 'OPENSEA').reduce(function (prev, curr) {
            return prev.floorPrice < curr.floorPrice ? prev : curr;
          }).floorPrice * collection.blockchainCoin.lastPrice.prices[this.loggedUser.settings.currency] * item.balance;
          }
          break
        case "COST":
          value = item.unitaryCost[this.loggedUser.settings.currency] * item.balance
          break
        default:
          break
      }

      return value;
    },
    handleClickOnRow(row) {
      this.dialog = true
      this.selectedNft = row
    },
  }
}


</script>

<style>
</style>