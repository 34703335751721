var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{staticClass:"pa-3",attrs:{"value":"tab-history"}},[_c('v-row',{staticClass:"my-1"},[_c('v-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('transaction-action-type-filter',{on:{"filterTransactionType":_vm.applyTransactionTypeFilter}})],1),_c('v-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dates,"persistent":"","width":"600px"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('label.dateRange'),"prepend-inner-icon":"mdi-calendar","hint":_vm.$t('label.dateRange'),"persistent-hint":"","readonly":"","solo":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","width":"600px","clearable":"","color":"accent"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.$refs.dialog.save([])}}},[_vm._v(_vm._s(_vm.$t('button.clear'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.dates)}}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)],1)],1)],1),_c('paged-data-table',{attrs:{"namespace":_vm.getNamespace,"itemEndpoint":_vm.getTableEndpoint,"itemsHeaders":_vm.getHeaders,"itemKey":"id","sortBy":"created","sortDesc":true,"query":_vm.getSearchQuery,"isCard":false},on:{"clickOnRow":_vm.handleClickOnRow},scopedSlots:_vm._u([{key:"item.blockchainType",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":require(("../../assets/blockchain/" + (item.blockchainType) + ".svg")),"alt":"","width":"25","aspect-ratio":"1","contain":""}})]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("PersonalAddressFormatter")(item.address))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("DateFormatter")(item.created))+" ")]}},{key:"item.transactionActionType",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm._f("TransactionActionTypeIconFormatter")(item.transactionActionType)))]),_c('span',[_vm._v(_vm._s(_vm._f("TransactionActionTypeStringFormatter")(item.transactionActionType)))])]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [_c('received-sent-component',{staticClass:"my-1",attrs:{"movements":item.received}})]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('received-sent-component',{staticClass:"my-1",attrs:{"movements":item.sent}})]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [(item.fee > 0)?_c('gas-value-component',{attrs:{"blockchainType":item.feeMovement.coin.symbol,"quantity":item.fee,"value":_vm.getFeeCurrencyValue(item)}}):_vm._e()]}}],null,true)}),_c('transaction-detail-dialog',{attrs:{"dialog":_vm.dialog,"transaction":_vm.selectedTransaction,"address":_vm.selectedAddress},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }